/* eslint-env browser, jquery */

(function($, window) {
    'use strict';

    $(function() {
        // Submit forms via AJAX.
        // =====================================================================
        var onSuccessCallbacks = {
            onAddComment: function(target) {
                var container = $(target).closest('.post-container');
                var numberOfReplies = container.find('.comment-total-number');
                var createBlock = container.find(
                    '.comment-create-block--rewards'
                );

                // Make sure that the comments section is open on mobile when
                // adding a comment.
                if (
                    $(container)
                        .find('.post-comments')
                        .hasClass('post-comments--open') === false
                ) {
                    var mobileComments = $(container).find(
                        '.mobile-view-comments'
                    );
                    if (mobileComments.is(':visible')) {
                        mobileComments.click();
                    }
                }

                // Increment the number of comments.
                if (numberOfReplies.length !== 0) {
                    var numberOfRepliesValue = Number(numberOfReplies.html());
                    ++numberOfRepliesValue;

                    // Update the page with the new count.
                    numberOfReplies.html(numberOfRepliesValue);
                }

                // Alter the create block.
                if (createBlock.length !== 0) {
                    createBlock.removeClass('comment-create-block--rewards');

                    var postId = 0;
                    var post = $(container).children('.anchor-tag:first');
                    if (post.length !== 0) {
                        postId = $(post)
                            .attr('id')
                            .replace('reward-', '');
                    }

                    var commentId = 0;
                    var comment = $(container).find(
                        '.post-comment-container:first-child > .anchor-tag:first'
                    );
                    if (comment.length !== 0) {
                        commentId = $(comment)
                            .attr('id')
                            .replace('post-comment-container-', '');
                    }

                    if (postId !== 0 && commentId !== 0) {
                        createBlock
                            .find('#reward_comment-' + postId + '_parent')
                            .append(
                                '<option value="' +
                                    commentId +
                                    '">' +
                                    commentId +
                                    '</option>'
                            )
                            .val(commentId);
                    }
                }
            },
            onAddCommentReply: function(target) {
                var container = $(target).closest('.post-comment-container');
                var numberOfReplies = container.find('.number-of-replies');
                var numberOfRepliesLabel = container.find(
                    '.number-of-replies-label'
                );

                // Increment the number of replies.
                var numberOfRepliesValue = Number(numberOfReplies.html());
                ++numberOfRepliesValue;

                // Update the page with the new count.
                numberOfReplies.html(numberOfRepliesValue);

                // Update the plurality of the label to match.
                if (numberOfRepliesValue === 1) {
                    numberOfRepliesLabel.html('REPLY');
                } else {
                    numberOfRepliesLabel.html('REPLIES');
                }
            },
        };

        var onSubmitCallbacks = {
            onAddRecipeFavorite: function(target) {
                target
                    .find('.heart-count')
                    .addClass('heart-active')
                    .prop('disabled', true);
            },
            onRemoveRecipeFavorite: function(target) {
                target
                    .find('.heart-count')
                    .removeClass('heart-active')
                    .prop('disabled', true);
            },
            onDismissNotification: function(target) {
                target.closest('.notification-list-item').hide();
            },
        };

        function showLoadingIcons(form) {
            var submitButtons = form.find('.button[type="submit"]');
            $(submitButtons).each(function() {
                $(this)
                    .addClass('hidden-on-submit')
                    .hide();
                $(this).after(
                    '<div class="button hidden-on-submit__loading">Loading</div>'
                );
            });
        }

        function removeLoadingIcons(form) {
            var submitButtons = form.find('.button[type="submit"]');
            $(submitButtons).each(function() {
                $(this)
                    .next('.hidden-on-submit__loading')
                    .remove();
                $(this)
                    .removeClass('hidden-on-submit')
                    .show();
            });
        }

        function removeUploadPreviews(form) {
            form.find('.form-preview-image').remove();
        }

        /**
         * Submit forms with ajax and replace content
         * with what was returned.
         */
        function ajaxLoadForm(e) {
            var $this = $(this);

            // Get the element being acted on.
            var container = $this.closest('.ajax-element');

            // Find out if the browser supports file uploads.
            var supportFormData = false;
            if (window.FormData) {
                supportFormData = true;
            }

            // Find out if the form has files.
            var hasFiles = false;
            $this.find('input[type=file]').each(function() {
                if ($(this).val()) {
                    hasFiles = true;
                }
            });

            // Legacy browsers don't support file uploads.
            // Don't use ajax if a legacy browser is uploading
            // a file.
            if (supportFormData === false && hasFiles === true) {
                return;
            }

            // Hide submit buttons and show loading icon.
            showLoadingIcons($this);

            // Run a specified callbacks.
            if (container.data('ajaxOnSubmit')) {
                onSubmitCallbacks[container.data('ajaxOnSubmit')]($this);
            }

            // Prevent the normal form submission.
            e.preventDefault();

            var method = $this.attr('method');

            var action = $this.attr('action') + '?ajax=1';

            var opts = {
                method: method,
                url: action,
                cache: false,
                error: function(jqXHR) {
                    var errors = [];
                    var fieldName;
                    var errorMessage;
                    var i;
                    var error;
                    var errorHtml;

                    // Debug errors.
                    // console.log(jqXHR);

                    // Restore submit button
                    removeLoadingIcons($this);

                    if (typeof jqXHR.responseJSON !== 'undefined') {
                        for (error in jqXHR.responseJSON.error) {
                            /* jscs:disable requireCamelCaseOrUpperCaseIdentifiers */
                            fieldName =
                                jqXHR.responseJSON.error[error].field_name;
                            errorMessage =
                                jqXHR.responseJSON.error[error].error_message;
                            /* jscs:enable requireCamelCaseOrUpperCaseIdentifiers */

                            if (!errors[fieldName]) {
                                errors[fieldName] = [];
                            }

                            errors[fieldName].push(errorMessage);
                        }
                    }

                    // Build the error message element.
                    errorHtml = '';
                    for (fieldName in errors) {
                        if (errors[fieldName].length === 1) {
                            // Put single errors in a span element.
                            errorHtml =
                                '<span class="form-field-error-message">' +
                                errors[fieldName][0] +
                                '</span>';
                        } else {
                            // Put multiple errors in a list element.
                            errorHtml = '<ul class="form-field-error-list">';
                            for (i in errors[fieldName]) {
                                errorHtml +=
                                    '<li class="form-field-error-message">' +
                                    errors[fieldName][i] +
                                    '</li>';
                            }
                            errorHtml += '</ul>';
                        }
                    }

                    errorHtml =
                        '<div title="There were errors">' +
                        errorHtml +
                        '</div>';

                    // Don't show errors.
                    if (container.data('ajaxHideErrors')) {
                        return;
                    }

                    // Show errors.
                    $(errorHtml).dialog({
                        modal: true,
                        buttons: {
                            Ok: function() {
                                $(this).dialog('close');
                            },
                        },
                    });
                },
                success: function(resp) {
                    // Restore submit button
                    removeLoadingIcons($this);

                    // Remove any upload previews from the form.
                    removeUploadPreviews($this);

                    // Reset the form
                    $this
                        .find(
                            'input[type=text], input[type=password], input[type=number], input[type=date], input[type=file], select:not(select[name$="[tellUs]"]):not(select[name$="[parent]"]):not(select[name$="[reward]"]), textarea'
                        )
                        .val('');

                    // Update the page with the returned html.

                    // Specifiy the element to append to.
                    if (container.data('ajaxAppend')) {
                        $(container.data('ajaxAppend')).append(resp);
                    }

                    // Specifiy an element to append to, and find that
                    // element by using closest() on the target element
                    // instead of the selector directly.
                    if (container.data('ajaxBubbleAppend')) {
                        container
                            .closest(container.data('ajaxBubbleAppend'))
                            .append(resp);
                    }

                    // Specifiy the element to prepend to.
                    if (container.data('ajaxPrepend')) {
                        $(container.data('ajaxPrepend')).prepend(resp);
                    }

                    // Specifiy an element to prepend to, and find that
                    // element by using closest() on the target element
                    // instead of the selector directly.
                    if (container.data('ajaxBubblePrepend')) {
                        container
                            .closest(container.data('ajaxBubblePrepend'))
                            .prepend(resp);
                    }

                    // Specifiy an element to replace.
                    if (container.data('ajaxReplace')) {
                        $(container.data('ajaxReplace')).replaceWith(resp);
                    }

                    // Specifiy an element to replace, and find that
                    // element by using closest() on the target element
                    // instead of the selector directly.
                    if (container.data('ajaxBubbleReplace')) {
                        container
                            .closest(container.data('ajaxBubbleReplace'))
                            .replaceWith(resp);
                    }

                    // Replace the current ajax-element with the new content.
                    if (container.data('ajaxSelfReplace')) {
                        container.replaceWith(resp);
                    }

                    // Specifiy an element to remove.
                    if (container.data('ajaxRemove')) {
                        $(container.data('ajaxRemove')).remove();
                    }

                    // Specifiy an element to remove, and find that
                    // element by using closest() on the target element
                    // instead of the selector directly.
                    if (container.data('ajaxBubbleRemove')) {
                        container
                            .closest(container.data('ajaxBubbleRemove'))
                            .remove();
                    }

                    // Run a specified callbacks.
                    if (container.data('ajaxOnSuccess')) {
                        onSuccessCallbacks[container.data('ajaxOnSuccess')](
                            $this
                        );
                    }
                },
            };

            var data;
            if (supportFormData === true) {
                // Modern Ajax 2 Support (File uploads work.)
                // FormData doesn't exist in IE 8 or 9.
                data = new FormData($this[0]);

                opts.data = data;
                opts.contentType = false;
                opts.processData = false;
            } else {
                // Legacy AJAX 1 support. (File uploads don't work.)
                data = $this.serialize();

                opts.data = data;
            }

            $.ajax(opts);
        }

        /**
         * Submit ajax requests in the background when clicking
         * certain links.
         */
        function ajaxBackgroundPost() {
            var action = $(this).data('ajaxBackgroundPost');

            action = action + '?ajax=1';

            $.ajax({
                method: 'POST',
                url: action,
                // error: function(jqXHR) {
                //     console.log(jqXHR.responseText);
                // },
                // success: function(resp) {
                //     console.log(resp);
                // }
            });
        }

        // Delegate.
        // =====================================================================
        $('body').on(
            'submit.ajaxLoadForm',
            '.ajax-element__form',
            ajaxLoadForm
        );

        $('body').on(
            'click.ajaxClickBackgroundForm',
            '.ajax-element__background',
            ajaxBackgroundPost
        );
    });
})(jQuery, window);
