/* eslint-env browser, jquery */

(function($) {
    'use strict';

    $(function() {
        function getInternetExplorerVersion() {
            var ua;
            var re;
            var rv = -1;
            if (navigator.appName === 'Microsoft Internet Explorer') {
                ua = navigator.userAgent;
                /* eslint-disable no-useless-escape */
                re = new RegExp('MSIE ([0-9]{1,}[.0-9]{0,})');
                /* eslint-enable no-useless-escape */
                if (re.exec(ua) !== null) {
                    rv = parseFloat(RegExp.$1);
                }
            } else if (navigator.appName === 'Netscape') {
                ua = navigator.userAgent;
                /* eslint-disable no-useless-escape */
                re = new RegExp('Trident/.*rv:([0-9]{1,}[.0-9]{0,})');
                /* eslint-enable no-useless-escape */
                if (re.exec(ua) !== null) {
                    rv = parseFloat(RegExp.$1);
                }
            }

            return rv;
        }

        function supportsChecked() {
            // Create a hidden input, style it, and then check to see whether the styles are applied.
            // Inspired by Modernizr's testStyles function
            var $style = $(
                '<style type="text/css"> #checkedPolyfill-test:checked { margin-left: 123456px; display: none; } </style>}'
            );
            var $checkbox = $(
                '<input type="checkbox" checked="checked" id="checkedPolyfill-test" />'
            );
            var result;

            $('head').append($style);
            $('body').append($checkbox);
            if ($checkbox.css('margin-left') === '123456px') {
                result = true;
            } else {
                result = false;
            }
            $style.remove();
            $checkbox.remove();
            return result;
        }

        function checkboxFix(selector) {
            $(selector)
                .parent()
                .click(function(e) {
                    if (e.target.type !== 'checkbox') {
                        var $checkbox = $('input[type="checkbox"]', this);

                        $checkbox.prop('checked', !$checkbox.prop('checked'));

                        $(this)
                            .parent()
                            .find('input[type="checkbox"]')
                            .change();
                    }
                });

            $(selector)
                .change(function() {
                    if ($(this).is(':checked') === true) {
                        $(this).addClass('checked');
                    } else {
                        $(this).removeClass('checked');
                    }

                    // Crazy IE8 Repaint Fix
                    if ($(this).next('span').length !== 0) {
                        $(this)
                            .next()
                            .remove();
                        $(this).after('<span></span>');
                    }

                    // Fix for IE8 not refreshing on input class change.
                    $(this)
                        .parent()
                        .addClass('refreshlayout')
                        .removeClass('refreshlayout');
                })
                .change();
        }

        function radioFix(selector) {
            $(selector)
                .parent()
                .click(function(e) {
                    if (e.target.type !== 'radio') {
                        var $radio = $('input[type="radio"]', this);

                        $radio.prop('checked', !$radio.prop('checked'));

                        $(this)
                            .parent()
                            .find('input[type="radio"]')
                            .change();
                    }
                });

            $(selector)
                .change(function() {
                    if ($(this).is(':checked') === true) {
                        $(this).addClass('checked');
                    } else {
                        $(this).removeClass('checked');
                    }

                    // Fix for IE8 not refreshing on input class change.
                    $(this)
                        .parent()
                        .addClass('refreshlayout')
                        .removeClass('refreshlayout');
                })
                .change();
        }

        if (supportsChecked() === false) {
            // IE8 Checked Pollyfill
            checkboxFix('input[type="checkbox"]');
            radioFix('input[type="radio"]');
        }

        if (getInternetExplorerVersion() === 11) {
            // IE11 Avatar radio button fix.
            radioFix('.update-avatar-form input[type="radio"]');
        }
    });
})(jQuery);
