/* eslint-env browser, jquery */

(function($) {
    'use strict';

    $(function() {
        // Real password field contents on click.
        // =====================================================================
        function viewPasswords(e) {
            e.preventDefault();

            $(this).toggleClass('passwords-visible');

            if ($(this).hasClass('passwords-visible')) {
                $('[type="password"]')
                    .addClass('visible-password')
                    .prop('type', 'text');
                $(this).html('Hide Passwords');
            } else {
                $('.visible-password')
                    .removeClass('visible-password')
                    .prop('type', 'password');
                $(this).html('Show Passwords');
            }
        }

        // Delegate.
        // =====================================================================
        $('body').on(
            'click.viewPasswords',
            '.button--view-passwords',
            viewPasswords
        );
    });
})(jQuery);
