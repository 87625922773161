/* eslint-env browser, jquery */

(function($) {
    'use strict';

    $(function() {
        // Log social sharing usage in the background.
        // =====================================================================
        function logSocialShare() {
            var container = $(this).closest('.addthis_toolbox');

            // Determine which type of content is being shared.
            var contentType = container.data('contentType');
            var contentId = container.data('contentId');

            // Determine which service is being shared to.
            var service = 'other';
            if ($(this).hasClass('at-svc-facebook')) {
                service = 'Facebook';
            } else if ($(this).hasClass('at-svc-twitter')) {
                service = 'Twitter';
            } else if ($(this).hasClass('at-svc-pinterest_share')) {
                service = 'Pinterest';
            } else if ($(this).hasClass('at-svc-google_plusone_share')) {
                service = 'Google Plus';
            } else if ($(this).hasClass('at-svc-email')) {
                service = 'Email';
            }

            var data = {
                user_social_log: {
                    service: service,
                    contentType: contentType,
                    contentId: contentId,
                },
            };

            // Log in the background via ajax.
            $.ajax({
                method: 'POST',
                url: '/social-log/add?ajax=1',
                data: data,
                // success: function(resp) {
                //     console.log(resp);
                // },
            });
        }

        // Delegate.
        // =====================================================================
        $('body').on(
            'click.logSocialShare',
            '.addthis_toolbox a',
            logSocialShare
        );
    });
})(jQuery);
