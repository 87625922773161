/* global EXIF */
/* eslint-env browser, jquery */

(function($, window, document) {
    'use strict';

    $(function() {
        // Show preview of image that will be uploaded for forms.
        // =====================================================================
        function updateImagePreview(previewTarget, dataUrl) {
            // Create the new image.
            var img =
                '<img class="form-preview-image" src="' + dataUrl + '" />';

            // Get any existing preview image.
            var existingImg = previewTarget.children('.form-preview-image');

            // If there is already an image replace it instead of removing
            // it and adding a new image.  This prevents the page height
            // from shifting.
            if (existingImg.length !== 0) {
                existingImg.replaceWith(img);
            } else {
                // Add the image to the page.
                previewTarget.append(img);
            }
        }

        function imagePreview() {
            // Find out if the browser supports the file reader api.
            var supportFileReader = false;
            if (window.FileReader) {
                supportFileReader = true;
            }

            // If the browser doesn't support FileReader just skip it.
            if (supportFileReader === false) {
                return;
            }

            var input = $(this);

            // Get the fields files.
            var inputFiles = this.files;

            // Make sure a file is set.
            if (typeof inputFiles === 'undefined' || inputFiles.length === 0) {
                return;
            }

            // Get the element to append the preview image to.
            var previewTarget = input.closest('.field');
            if (input.closest('.inline-form-wrapper').length !== 0) {
                previewTarget = input.closest('.inline-form-wrapper').parent();
            }

            // Read in the file from the disk.
            var file = inputFiles[0];

            // Read the files orientation, rotate if needed, and update the
            // page to display a preview.
            EXIF.getData(file, function() {
                var orientation = EXIF.getTag(this, 'Orientation');
                var can = document.createElement('canvas');
                var ctx = can.getContext('2d');

                var thisImage = new Image();

                thisImage.onload = function() {
                    can.width = thisImage.width;
                    can.height = thisImage.height;
                    ctx.save();

                    var width = can.width;
                    var styleWidth = can.style.width;
                    var height = can.height;
                    var styleHeight = can.style.height;

                    if (orientation) {
                        if (orientation > 4) {
                            can.width = height;
                            can.style.width = styleHeight;
                            can.height = width;
                            can.style.height = styleWidth;
                        }

                        switch (orientation) {
                            case 2:
                                ctx.translate(width, 0);
                                ctx.scale(-1, 1);
                                break;
                            case 3:
                                ctx.translate(width, height);
                                ctx.rotate(Math.PI);
                                break;
                            case 4:
                                ctx.translate(0, height);
                                ctx.scale(1, -1);
                                break;
                            case 5:
                                ctx.rotate(0.5 * Math.PI);
                                ctx.scale(1, -1);
                                break;
                            case 6:
                                ctx.rotate(0.5 * Math.PI);
                                ctx.translate(0, -height);
                                break;
                            case 7:
                                ctx.rotate(0.5 * Math.PI);
                                ctx.translate(width, -height);
                                ctx.scale(-1, 1);
                                break;
                            case 8:
                                ctx.rotate(-0.5 * Math.PI);
                                ctx.translate(-width, 0);
                                break;
                            default:
                                break;
                        }
                    }

                    ctx.drawImage(thisImage, 0, 0);
                    ctx.restore();
                    var dataURL = can.toDataURL();

                    // Update the page to show the preview image.
                    updateImagePreview(previewTarget, dataURL);
                };

                // Trigger the file load.
                thisImage.src = URL.createObjectURL(file);
            });
        }

        // Delegate.
        // =====================================================================
        $('body').on('change.imagePreview', 'input[type="file"]', imagePreview);
    });
})(jQuery, window, document);
