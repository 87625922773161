/* eslint-env browser, jquery */

(function($) {
    'use strict';

    $(function() {
        // Remove simple close buttons on click.
        // =====================================================================
        function closeAlertMessage(e) {
            e.preventDefault();

            $(this)
                .closest('.alert-message')
                .remove();
        }

        // Delegate.
        // =====================================================================
        $('body').on(
            'click.closeAlertMessage',
            '.close-btn--flash, .close-btn--simple',
            closeAlertMessage
        );
    });
})(jQuery);
