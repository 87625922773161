/* eslint-env browser, jquery */

(function($) {
    'use strict';

    // Browser supports HTML5 multiple file?
    var multipleSupport = typeof $('<input/>')[0].multiple !== 'undefined';
    var isIE = /msie/i.test(navigator.userAgent);

    function getInternetExplorerVersion() {
        var ua;
        var re;
        var rv = -1;
        if (navigator.appName === 'Microsoft Internet Explorer') {
            ua = navigator.userAgent;
            /* eslint-disable no-useless-escape */
            re = new RegExp('MSIE ([0-9]{1,}[.0-9]{0,})');
            /* eslint-enable no-useless-escape */
            if (re.exec(ua) !== null) {
                rv = parseFloat(RegExp.$1);
            }
        } else if (navigator.appName === 'Netscape') {
            ua = navigator.userAgent;
            /* eslint-disable no-useless-escape */
            re = new RegExp('Trident/.*rv:([0-9]{1,}[.0-9]{0,})');
            /* eslint-enable no-useless-escape */
            if (re.exec(ua) !== null) {
                rv = parseFloat(RegExp.$1);
            }
        }

        return rv;
    }

    $.fn.customFile = function() {
        return this.each(function() {
            // Disable in IE8 because it doesn't work.
            if (getInternetExplorerVersion() === 8) {
                return;
            }

            // the original file input
            var $file = $(this).addClass('custom-file-upload-hidden');
            var $wrap = $('<div class="file-upload-wrapper">');
            var $input = $(
                '<input type="text" class="file-upload-input" placeholder="Choose a file..." />'
            );
            // Button that will be used in non-IE browsers
            var $button = $('<a class="file-upload-button">Select a File</a>');
            // Hack for IE
            var $label = $(
                '<label class="file-upload-button" for="' +
                    $file[0].id +
                    '">Select a File</label>'
            );

            // Hide by shifting to the left so we
            // can still trigger events
            $file.css({
                position: 'absolute',
                left: '-9999px',
            });

            $wrap
                .insertAfter($file)
                .append($file, $input, isIE ? $label : $button);

            // Prevent focus
            $file.attr('tabIndex', -1);
            $button.attr('tabIndex', -1);

            $button.click(function(e) {
                e.preventDefault();
                // Open dialog
                $file.focus().click();
            });

            $input.click(function(e) {
                e.preventDefault();
                // Open dialog
                $file.focus().click();
            });

            $file.change(function() {
                var files = [];
                var fileArr;
                var filename;

                // If multiple is supported then extract
                // all filenames from the file array
                if (multipleSupport) {
                    fileArr = $file[0].files;
                    for (var i = 0, len = fileArr.length; i < len; i++) {
                        files.push(fileArr[i].name);
                    }
                    filename = files.join(', ');

                    // If not supported then just take the value
                    // and remove the path to just show the filename
                } else {
                    filename = $file
                        .val()
                        .split('\\')
                        .pop();
                }

                $input
                    .val(filename) // Set the value
                    .attr('title', filename) // Show filename in title tootlip
                    .focus(); // Regain focus
            });

            $input.on({
                blur: function() {
                    $file.trigger('blur');
                },
                keydown: function(e) {
                    if (e.which === 13) {
                        // Enter
                        if (!isIE) {
                            $file.trigger('click');
                        }
                    } else if (e.which === 8 || e.which === 46) {
                        // Backspace & Del
                        // On some browsers the value is read-only
                        // with this trick we remove the old input and add
                        // a clean clone with all the original events attached
                        $file.replaceWith(($file = $file.clone(true)));
                        $file.trigger('change');
                        $input.val('');
                    } else if (e.which === 9) {
                        // TAB
                        return;
                    } else {
                        // All other keys
                        e.preventDefault();
                    }
                },
            });
        });
    };
})(jQuery);

function initCustomFileUploadField() {
    'use strict';

    jQuery('.custom-file-upload-field:not(.initCustomFileUpload)')
        .addClass('initCustomFileUpload')
        .customFile();
}

(function($) {
    'use strict';

    $(function() {
        // Custom File Upload Field.
        // =====================================================================
        initCustomFileUploadField();
    });
})(jQuery);
