/* eslint-env browser, jquery */

(function($) {
    'use strict';

    $(function() {
        // Toogle the share toolbar.
        // =====================================================================
        function toggleShareToolbar(e) {
            e.preventDefault();
            e.stopPropagation();

            $(this)
                .next('.share-toolbar')
                .toggle();
        }

        function closeAllShareToolbars() {
            $('.share-toolbar-toggle').each(function() {
                if (
                    $(this)
                        .next('.share-toolbar')
                        .is(':visible')
                ) {
                    $(this).click();
                }
            });
        }

        // Delegate.
        // =====================================================================
        $('body').on(
            'click.toggleShareToolbar',
            '.share-toolbar-toggle',
            toggleShareToolbar
        );

        // Close the toolbar when clicking outside of it.
        $('html').click(closeAllShareToolbars);
    });
})(jQuery);
